import React from 'react'

function AddStudentCouncil() {
    return (
        <>

        </>
    )
}

export default AddStudentCouncil